<template lang="pug">
Modal(:show="showModal", :disable_close="true")
  CardPayments
    template(v-slot:content)
      .head.pb-4 Error en el pago
      .sub-head.pb-2 Es posible que haya introducido incorrectamente su tarjeta de credito
      .sub-head.pb-4 Por favor, vuelva a intentarlo o cambie de tarjeta.
      //- .spinner
      //-   CircleLoader
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";
import PaymentsRepository from "@/app/repository/payments.repository";

export default {
  name: "ProcessPaymentError",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const query = route.query;
    const showModal = ref(true);

    const paymentID = query.payment_id;

    const processPayment = async (cc_id) => {
      PaymentsRepository.getLiteDataPayment(paymentID)
        .then(({ user_rel, subscription_rel }) => {
          store.dispatch(
            "plansSubscriptionsStore/changePlan",
            subscription_rel
          );
          store.dispatch("userStore/updateUserID", user_rel);
          console.log({ user_rel, subscription_rel });

          setTimeout(() => {
            showModal.value = false;
          }, 3500);

          setTimeout(() => {
            router.replace({ name: "Payment" });
          }, 4000);
        })
        .catch((error) => {
          redirectToQuizMail();
        });
    };

    if (paymentID) {
      processPayment();
    } else {
      redirectToQuizMail();
    }
    const redirectToQuizMail = () => router.push({ name: "Quiz_email" });

    return {
      showModal,
    };
  },
};
</script>
<style lang="scss" scoped>
.head {
  @apply text-xl font-bold text-red-400 text-center;
  span {
    @apply text-green-600;
  }
}

.sub-head {
  @apply text-sm font-medium text-gray-500 text-center;
}
.spinner {
  min-height: 240px;
  @apply pb-4 pt-4 flex justify-center items-center;
}
</style>